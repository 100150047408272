import styles from './footer.module.scss'

export default function Footer() {
	return (
		<footer className={styles.footer}>
			<div className={styles.footerContent}>
				<p>Copyright 2021 nftmonitor.io</p>
			</div>
		</footer>
	)
}
