/* eslint-disable no-underscore-dangle */
/* global SigV4Utils, Paho */

import { ReactComponent as Flash } from 'assets/icons/flash.svg'
import useDrag from 'hooks/useDrag'
import classNames from 'classnames/bind'
import AWS from 'aws-sdk'
import { useState, MouseEvent, useCallback, useEffect } from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import MediaQuery from 'react-responsive'
import { scrollVisibilityApiType, onWheel } from 'pages/landing/utils'
import ContentLoader from 'react-content-loader'

import NavBar from 'components/navbar/navbar'
import Footer from 'components/footer/footer'
import solanaBanner from 'assets/images/solana_banner.jpeg'

import styles from './landing.module.scss'
import './hideScrollbar.scss'

const cx = classNames.bind(styles)

function getRequestURL() {
	return SigV4Utils.getSignedUrl(
		'wss',
		'a3lvh6w4b5yz3-ats.iot.us-west-2.amazonaws.com',
		'/mqtt',
		'iotdevicegateway',
		'us-west-2',
		AWS.config.credentials.accessKeyId,
		AWS.config.credentials.secretAccessKey,
		AWS.config.credentials.sessionToken
	)
}

function makeId(length) {
	let result = ''
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	const charactersLength = characters.length
	for (let i = 0; i < length; i += 1) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}

export default function Landing() {
	const [trendingTabActiveIdx, setTrendingTabActiveIdx] = useState(0)
	const { dragStart, dragStop, dragMove, dragging } = useDrag()
	const [selected, setSelected] = useState<string>('')
	const [selectedNft, setSelectedNft] = useState(null)
	const [nfts, setNfts] = useState([])
	const [trendingLast1h, setTrendingLast1h] = useState([])
	const [trendingLast24h, setTrendingLast24h] = useState([])

	const addNFT = msg => {
		setNfts(n => {
			let clonedNfts = [...n]
			const NUMBER_OF_NFTS_IN_ROTATION = 5

			if (clonedNfts.findIndex(nft => nft.data.token === msg.data.token) !== -1) {
				return clonedNfts
			}

			clonedNfts.unshift(msg)
			if (clonedNfts.length > NUMBER_OF_NFTS_IN_ROTATION) {
				clonedNfts = clonedNfts.slice(0, 5)
			}

			setSelectedNft(clonedNfts[0])
			return clonedNfts
		})
	}

	const addTrending = msg => {
		if (msg.data.trending60) {
			setTrendingLast1h(msg.data.trending60)
		} else if (msg.data.trending1440) {
			setTrendingLast24h(msg.data.trending1440)
		}
	}

	useEffect(() => {
		AWS.config.update({ region: 'us-west-2' })
		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: 'us-west-2:5814a160-9b31-4ea3-a5be-7c60ee227786',
		})

		const credentials = AWS.config.credentials as AWS.Credentials

		credentials.get(() => {
			const topic = 'nftmonitor'
			const requestUrl = getRequestURL()

			const client = new Paho.Client(requestUrl, makeId(20))
			const connectOptions = {
				onSuccess() {
					client.subscribe(topic)
				},
				useSSL: true,
				timeout: 10,
				mqttVersion: 4,
				keepAliveInterval: 1200, // max 1200 on AWS IoT
				reconnect: true,
				onFailure: error => {
					// eslint-disable-next-line no-console
					console.error(`connect failed${error.errorMessage}`)
				},
			}
			client.connect(connectOptions)
			client.onMessageArrived = message => {
				const msg = JSON.parse(message.payloadString)
				if (msg.type === 'mint') {
					addNFT(msg)
				} else if (msg.type === 'stats') {
					addTrending(msg)
				}
			}
		})
	}, [])

	// CALLBACKS
	const handleDrag =
		({ scrollContainer }: scrollVisibilityApiType) =>
		(ev: MouseEvent) =>
			dragMove(ev, posDiff => {
				if (scrollContainer.current) {
					// eslint-disable-next-line no-param-reassign
					scrollContainer.current.scrollLeft += posDiff
				}
			})

	const handleItemClick = (itemId: string) => () => {
		if (dragging) {
			return false
		}
		setSelected(selected !== itemId ? itemId : '')
		return null
	}

	const handleTabClick = useCallback(
		(index: number) => () => {
			setTrendingTabActiveIdx(index)
		},
		[]
	)

	const viewedNft = selectedNft || nfts[0]
	return (
		<>
			<NavBar />
			<div className={styles.landing}>
				<section className={styles.header}>
					<div className={styles.heading}>
						<h1 className={styles.headingMain}>
							Discover realtime NFT Mints on{' '}
							<span className={styles.solanaText}>Solana</span>
						</h1>
 					</div>

					{nfts.length > 0 ? (
						<>
							<MediaQuery maxWidth={767}>
								<section onMouseLeave={dragStop} className={styles.cards}>
									<ScrollMenu
										onWheel={onWheel}
										onMouseDown={() => dragStart}
										onMouseUp={() => dragStop}
										onMouseMove={handleDrag}
									>
										{nfts.map((nft, index) => (
											<a
												className={cx({
													card: true,
													cardFirst: index === 0,
												})}
												key={nft.id}
												target='_blank'
												href={nft.data.external_url}
												rel='noreferrer'
												onClick={handleItemClick(nft.id)}
											>
												<img
													draggable='false'
													onDragStart={() => false}
													className={styles.cardImg}
													src={nft.data.image}
													alt={nft.data.name || 'nft'}
												/>
												<div className={styles.cardInfo}>
													<div className={styles.cardInfoName}>{nft.data.name}</div>
													<div className={styles.cardInfoSource}>{nft.data.collection}</div>
												</div>
											</a>
										))}
									</ScrollMenu>
								</section>
							</MediaQuery>

							<MediaQuery minWidth={768}>
								<div className={styles.gallery}>
									{viewedNft && (
										<a
											className={styles.galleryItemMain}
											target='_blank'
											href={viewedNft.data.external_url}
											rel='noreferrer'
										>
											<span>
												<img
													draggable='false'
													onDragStart={() => false}
													className={styles.galleryItemImg}
													src={viewedNft.data.image}
													alt={viewedNft.data.name || 'nft'}
												/>
												<div className={styles.galleryItemInfo}>
													<div className={styles.galleryItemInfoName}>
														{viewedNft.data.name}
													</div>
													<div className={styles.galleryItemInfoSource}>
														{viewedNft.data.collection}
													</div>
												</div>
											</span>
										</a>
									)}
									{nfts.map(nft => (
										<div
											role='button'
											aria-hidden='true'
											className={styles.galleryItemThumb}
											key={nft.id}
											onClick={() => setSelectedNft(nft)}
										>
											<span>
												<img
													draggable='false'
													onDragStart={() => false}
													src={nft.data.image}
													alt={nft.data.name || 'nft'}
												/>
											</span>
										</div>
									))}
								</div>
							</MediaQuery>
						</>
					) : (
						<div className={styles.spinner}>
							<div className={`${styles.orbitBlue} ${styles.leo}`} />
							<div className={`${styles.orbitGreen} ${styles.leo}`} />
							<div className={`${styles.orbitRed} ${styles.leo}`} />
							<div className={`${styles.orbitWhite} ${styles.w1} ${styles.leo}`} />
							<div className={`${styles.orbitWhite} ${styles.w2} ${styles.leo}`} />
							<div className={`${styles.orbitWhite} ${styles.w3} ${styles.leo}`} />
						</div>
					)}
				</section>

				<section className={styles.trending}>
					<h3>
						Trending Mints <Flash className={styles.flashIcon} />
					</h3>
					<div className={styles.trendingMints}>
						<div
							className={cx({
								trendingTimeLabel: true,
								trendingTimeActive: trendingTabActiveIdx === 0,
							})}
							role='button'
							aria-hidden='true'
							onClick={handleTabClick(0)}
						>
							Last 1h
						</div>
						<div
							className={cx({
								trendingTimeLabel: true,
								trendingTimeActive: trendingTabActiveIdx === 1,
							})}
							role='button'
							aria-hidden='true'
							onClick={handleTabClick(1)}
						>
							Last 24h
						</div>
						<div
							className={cx({
								mints1h: true,
								hide: trendingTabActiveIdx !== 0,
							})}
						>
							{trendingLast1h.length > 0
								? trendingLast1h.map((trending, index) => (
										<a
											key={trending.collection}
											className={styles.mint}
											target='_blank'
											href={trending.external_url}
											rel='noreferrer'
										>{`${index + 1}. ${trending.collection}`}</a>
								  ))
								: Array.from({ length: 5 }).map((_, index) => (
										<div key={index} className={styles.mintPlaceholder}>
											<ContentLoader
												speed={2}
												height='100%'
												width='100%'
												backgroundColor='#03132C'
												foregroundColor='#57BCD8'
											>
												<rect x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
											</ContentLoader>
										</div>
								  ))}
						</div>
						<div
							className={cx({
								mints24h: true,
								hide: trendingTabActiveIdx !== 1,
							})}
						>
							{trendingLast24h.length > 0
								? trendingLast24h.map((trending, index) => (
										<a
											key={trending.collection}
											className={styles.mint}
											target='_blank'
											href={trending.external_url}
											rel='noreferrer'
										>{`${index + 1}. ${trending.collection}`}</a>
								  ))
								: Array.from({ length: 5 }).map((_, index) => (
										<div key={index} className={styles.mintPlaceholder}>
											<ContentLoader
												speed={2}
												height='100%'
												width='100%'
												backgroundColor='#03132C'
												foregroundColor='#57BCD8'
											>
												<rect x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
											</ContentLoader>
										</div>
								  ))}
						</div>
					</div>

					<a
						className={styles.banner}
						href='https://twitter.com/SOLParasitesNFT'
						target='_blank'
						rel='noreferrer'
					>
						<img
							src={solanaBanner}
							alt='solana banner'
							className={styles.bannerImg}
						/>
					</a>
				</section>
			</div>
			<Footer />
		</>
	)
}
