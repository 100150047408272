import { ContextType, WheelEvent } from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'

export type scrollVisibilityApiType = ContextType<typeof VisibilityContext>

export function onWheel(apiObj: scrollVisibilityApiType, ev: WheelEvent): void {
	const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15

	if (isThouchpad) {
		ev.stopPropagation()
		return
	}

	if (ev.deltaY < 0) {
		apiObj.scrollNext()
	} else if (ev.deltaY > 0) {
		apiObj.scrollPrev()
	}
}
