import { ReactComponent as Logo } from 'assets/icons/logo.svg'
import { ReactComponent as LogoText } from 'assets/icons/logotext.svg'
import { ReactComponent as Discord } from 'assets/icons/discord.svg'
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg'
import { ReactComponent as Youtube } from 'assets/icons/youtube.svg'
import { useState, useCallback, useEffect } from 'react'
import classNames from 'classnames/bind'
import styles from './navbar.module.scss'

const cx = classNames.bind(styles)

export default function NavBar() {
	const [menuOpen, setOpenMenu] = useState(false)
	const toggleMenu = useCallback(() => {
		setOpenMenu(state => !state)
	}, [])
	const [isSticky, setIsSticky] = useState(false)

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.pageYOffset
			setIsSticky(currentScrollPos > 80)
		}

		window.addEventListener('scroll', handleScroll)

		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	return (
		<nav className={cx({ navbar: true, navbarFixed: isSticky })}>
			<div className={`${styles.navbarContainer} ${styles.container}`}>
				<div className={styles.logo}>
					<Logo className={styles.logoSvg} />
					<LogoText className={styles.logoTextSvg} />
				</div>

				<div
					aria-hidden='true'
					role='button'
					className={cx({
						hamburger: true,
						hamburgerOpen: menuOpen,
					})}
					onClick={toggleMenu}
				>
					<em className={styles.hamburgerLine} />
					<em className={styles.hamburgerLine} />
					<em className={styles.hamburgerLine} />
				</div>

				<ul className={styles.menuItems}>
					<li>
						<a
							href='https://twitter.com/nftmonitor_io'
							target='_blank'
							rel='noreferrer'
						>
							<Twitter />
						</a>
					</li>
					<li>
						<a href='https://discord.gg/UMuHcwbVtE' target='_blank' rel='noreferrer'>
							<Discord />
						</a>
					</li>
				</ul>
			</div>
		</nav>
	)
}
